export const addClassToElement = (el, className) => {
    if (el) {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                el.classList.add(className);
            });
        });
    }
};

export const removeClassFromElement = (el, className) => {
    if (el) {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                el.classList.remove(className);
            });
        });
    }
};

export const getAppElement = () => {
    return document.getElementById('__next');
};

export const getHeaderElement = () => {
    return document.getElementById('header');
};
