import React, { useCallback, useContext, useEffect, useState } from 'react';

import { emitter } from '../../../contracts/marketplace';
import { EVENT_TYPE } from '../../../models/event-type';
import { OPERATION_DESCRIPTION, OPERATION_TYPES } from '../../../models/marketplace';
import BlochainContext from '../../context/blockchain-provider';
import style from '../../ui/styles/styles.module.css';
import IcoDone from '../icons/done';
import IcoError from '../icons/error';
import IcoSuccess from '../icons/success';
import RKLButton from '../rkl-button';
import RKLLoadingSpinner from '../rkl-loading-spinner';
import css from './transaction-state.module.css';

export interface Props {
    isTransactionState: boolean;
    children: React.ReactNode;
    onClose(): void;
}

let blockNumber = null;

const TransactionState: React.FC<Props> = ({ children, isTransactionState, onClose }) => {
    const { provider } = useContext(BlochainContext);
    const [tnxHash, setTnxHash] = useState<string | undefined>(undefined);
    const [method, setMethod] = useState<string | undefined>(undefined);
    const [isSuccessful, setSuccessful] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const onResetAndClose = useCallback(() => {
        setTnxHash(undefined);
        setMethod(undefined);
        setSuccessful(false);
        blockNumber = null;
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (!tnxHash || !provider) return;

        const update = () => {
            provider.getTransactionReceipt(tnxHash).then((transactionReceipt) => {
                if (transactionReceipt && transactionReceipt.blockNumber != null) {
                    blockNumber = transactionReceipt.blockNumber;
                    emitter.emit(EVENT_TYPE.COMPLETE, {}, { method });
                    emitter.emit(EVENT_TYPE.BATCH_CHANGE, {}, { method });
                    if (method === OPERATION_TYPES.WITHDRAW) {
                        emitter.emit(EVENT_TYPE.BALANCE_CHANGE, {}, { method });
                    }
                    setSuccessful(true);
                } else {
                    window.setTimeout(() => update(), 1000);
                }
            });
        };

        if (tnxHash && provider && blockNumber == null) {
            update();
        }
    }, [provider, tnxHash, method]);

    useEffect(() => {
        const unsubscribeCreateEvent = emitter.on(EVENT_TYPE.CREATE, {}, (_, data) => {
            if (data.method) {
                setMethod(data.method);
            }
        });
        const unsubscribeTransactionEvent = emitter.on(EVENT_TYPE.TRANSACTION, {}, (_, data) => {
            if (data.tnxHash) {
                setTnxHash(data.tnxHash);
            }
        });
        const unsubscribeRejectEvent = emitter.on(EVENT_TYPE.USER_REJECT, {}, (_, data) => {
            if (data.message) {
                setError(data.message);
            }
        });
        const unsubscribedErrorEvent = emitter.on(EVENT_TYPE.ERROR, {}, (_, data) => {
            if (data.message) {
                setError(data.message);
            }
        });

        return () => {
            unsubscribeTransactionEvent();
            unsubscribeCreateEvent();
            unsubscribeRejectEvent();
            unsubscribedErrorEvent();
        };
    }, []);

    if (!isTransactionState) {
        return <>{children}</>;
    }

    if (error) {
        return (
            <div className={css.itemSuccess}>
                <div className={`${style.fontSizeMedium} ${style.mbMl}`}>{error}</div>
                <div className={style.mbMl}>
                    <IcoError />
                </div>
                <RKLButton size={'small'} color={'violet'} onClick={onResetAndClose}>
                    Close
                </RKLButton>
            </div>
        );
    }

    const methodDescription = method && OPERATION_DESCRIPTION[method];

    return (
        <>
            {method && !isSuccessful && (
                <div className={css.item}>
                    <div className={css.itemHead}>
                        {tnxHash ? <IcoDone /> : <RKLLoadingSpinner width={20} height={20} />}
                        <div className={css.itemHeadLabel}>
                            Confirm {methodDescription} operation
                        </div>
                    </div>
                    <div className={css.itemDescription}>
                        Sign the message in you wallet to confirm the {methodDescription} operation
                    </div>
                    <div className={`${style.gridRow} ${css.itemStatus}`}>
                        <div className={css.itemStatusLabel}>Status</div>
                        <div>{tnxHash ? 'Confirmed' : 'Waiting for you to confirm'}</div>
                    </div>
                </div>
            )}
            {tnxHash && !isSuccessful && (
                <div className={css.item}>
                    <div className={css.itemHead}>
                        <RKLLoadingSpinner width={20} height={20} />
                        <div className={css.itemHeadLabel}>Contract Interaction</div>
                    </div>
                    <div className={css.itemDescription}>
                        Transaction Hash&nbsp;<span className={css.tnxHash}>{tnxHash}</span>
                    </div>
                    <div className={`${style.gridRow} ${css.itemStatus}`}>
                        <div className={css.itemStatusLabel}>Status</div>
                        <div>{!isSuccessful ? 'Pending' : 'Confirmed'}</div>
                    </div>
                </div>
            )}
            {isSuccessful && (
                <div className={css.itemSuccess}>
                    <div className={`${style.fontSizeMedium} ${style.mbMl}`}>
                        Your {methodDescription} operation was completed
                    </div>
                    <div className={style.mbMl}>
                        <IcoSuccess />
                    </div>
                    <RKLButton size={'small'} color={'violet'} onClick={onResetAndClose}>
                        Close
                    </RKLButton>
                </div>
            )}
        </>
    );
};

export default TransactionState;
