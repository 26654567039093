import React from 'react';

const IcoSuccess: React.FC = () => {
    return (
        <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="34" cy="34" r="32.5" stroke="#B1F9E0" strokeWidth="3" />
            <path
                d="M20.5078 31.8411L31.8411 44.2538L48.0316 24.8252"
                stroke="#B1F9E0"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IcoSuccess;
