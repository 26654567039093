import { ApolloClient, InMemoryCache } from '@apollo/client';

import { GRAPHQL_API_HOST } from '../app.config';

const graphqlClient = new ApolloClient({
    uri: GRAPHQL_API_HOST,
    cache: new InMemoryCache(),
});

export default graphqlClient;
