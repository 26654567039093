import '../style/reset.css';
import '../style/variables.css';

import { ApolloProvider } from '@apollo/client';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import { GA_ID } from '../app.config';
import { AppContext, ModelParams } from '../components/context/app-context';
import { BlockchainProvider } from '../components/context/blockchain-provider';
import ErrorBoundary from '../components/ui/error-boundary';
import RKLModalDialog from '../components/ui/rkl-modal-dialog';
import TransactionState from '../components/ui/transaction-state';
import graphqlClient from '../services/graphql-client';
import { addClassToElement, removeClassFromElement } from '../utils/dom';

ReactGA.initialize(GA_ID, {
    debug: false,
    gaOptions: {
        siteSpeedSampleRate: 100,
    },
});

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const router = useRouter();
    const [isModelOpen, setModelOpen] = useState<boolean>(false);
    const [isModalTransactionState, setModalTransactionState] = useState<boolean>(false);
    const [modalParams, setModalParams] = useState<ModelParams>({
        content: <>&nbsp;</>,
        onClose: true,
        modelTransactionState: false,
    });

    const getLayout = Component.getLayout ?? ((page) => page);

    const modelTransactionState = useCallback(() => setModalTransactionState(true), []);

    const openModel = useCallback((params: ModelParams) => {
        setModalParams(params);
        addClassToElement(document.body, 'body-overflow');
        setModelOpen(true);
        if (params.modelTransactionState === true) {
            setModalTransactionState(true);
        }
    }, []);
    const closeModal = useCallback(() => {
        setModelOpen(false);
        removeClassFromElement(document.body, 'body-overflow');
        setModalTransactionState(false);
    }, []);

    const appContextValues = {
        openModel,
        closeModal,
        modelTransactionState,
    };

    useEffect(() => {
        ReactGA.pageview(router.asPath);
    }, [router.asPath]);

    return (
        <ErrorBoundary router={router}>
            <BlockchainProvider>
                <ApolloProvider client={graphqlClient}>
                    <AppContext.Provider value={appContextValues}>
                        <RKLModalDialog
                            isOpen={isModelOpen}
                            onClose={closeModal}
                            topView={modalParams.topView}
                            hasOnClose={modalParams.onClose}
                            borderColor={modalParams.borderColor}
                            width={modalParams.width}
                            height={modalParams.height}
                            padding={modalParams.padding}
                        >
                            <TransactionState
                                onClose={closeModal}
                                isTransactionState={isModalTransactionState}
                            >
                                {modalParams.content}
                            </TransactionState>
                        </RKLModalDialog>
                        {getLayout(<Component {...pageProps} />)}
                    </AppContext.Provider>
                </ApolloProvider>
            </BlockchainProvider>
        </ErrorBoundary>
    );
};

export default App;
