import React from 'react';

const IcoError: React.FC = () => {
    return (
        <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="34" cy="34" r="32.5" stroke="#AE80F0" strokeWidth="3" />
            <path
                d="M45.044 22.3775L21.876 45.5455M21.2663 22.9871L44.4343 46.1552"
                stroke="#AE80F0"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IcoError;
